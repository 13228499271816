import React, { useEffect } from "react";
import axios from "axios";
import { useVisible } from "react-hooks-visible";
import { snakeCase } from "snake-case";

import { shuffle } from "./utils";
import ImdbLogo from "./imdbLogo";
import MenuIcon from "./menu";
import "./App.css";
import movieGenres from "./movieGenres.json";
import SearchImdb from "./SearchImdb";

const getGenres = (genre_ids) =>
  genre_ids.reduce((acc, curr) => {
    return `${movieGenres[curr]}\n${acc}`;
  }, "");

const urlBase = "https://api.themoviedb.org/3";
const apiKey = "f60e163350786a33f9a2ef2a351ccacb";

const productId = "B1cu6eGOmWiHTOx5zPO8-A==";
const upgradeText = "Unlock the IMDb Peek addon here: bit.ly/xeno-imdb-peek";

let peekedMovies = [];

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const useDisablePinchZoomEffect = () => {
  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);
};

const PosterRow = ({ movies, title, onSelect, onDeselect, uuid }) => {
  const [shuffledMovies, setShuffledMovies] = React.useState([]);

  useEffect(() => {
    setShuffledMovies(shuffle(movies));
  }, [movies, setShuffledMovies]);

  return (
    <div className="categoryWrapper">
      <h1 className="categoryTitle">{title}</h1>
      <div className="movie-carousel">
        {movies &&
          movies.length &&
          shuffledMovies.map((movie, index) => (
            <Poster
              movie={movie}
              uuid={uuid}
              key={index}
              onSelect={onSelect}
              onDeselect={onDeselect}
            />
          ))}
      </div>
    </div>
  );
};

const posterPathForMovie = {};

const thump = async (title, uuid, genres, releaseDate) => {
  // window.location.assign(`${imdbTitleBaseUrl}${imdbSearch.data.d[0].id}`);
  // TODO: Redirect to backend immediately, and let backend thump and direct to imdb
  // if (!userId || userId === undefined) {
  //   return;
  // }
  // return axios.post("/api/thump", {
  //   posterPath: posterPathForMovie[movie],
  //   userId,
  //   movie,
  // });
  return axios.post("/api/xeno/thump", {
    uuid,
    text1: upgradeText,
    text2: "",
    addonText1: title,
    addonText2: genres ? `Release: ${releaseDate}\n${genres}` : "",
    productId,
    upgradeText,
  });
};

const thumpAndRedirect = async (title, uuid, genre_ids, releaseDate) => {
  const genres = getGenres(genre_ids);
  await thump(title, uuid, genres, releaseDate);

  const year =
    (releaseDate && releaseDate.split("-") && releaseDate.split("-")[0]) || "";

  window.location.replace(
    `https://imdbpicks.com/api/title/${snakeCase(title)}_${year}`
  );
};

const notify = (message, uuid) => {
  return thump(message, uuid);
};

const Poster = ({
  onSelect,
  uuid,
  onDeselect,
  movie: { poster_path, title, genre_ids, release_date },
}) => {
  const [targetRef, visible] = useVisible((visibility) => visibility > 0.85);

  React.useEffect(() => {
    if (visible) {
      onSelect({
        movie: title,
        posterPath: poster_path,
        genre_ids,
        releaseDate: release_date,
      });
    } else if (peekedMovies.map((movie) => movie.title).includes(title)) {
      onDeselect({ movie: title });
    }
  }, [
    visible,
    poster_path,
    onSelect,
    onDeselect,
    title,
    genre_ids,
    release_date,
  ]);

  const [clicked, setClicked] = React.useState(false);

  return (
    <div className="movieWrapper">
      <div className="posterWrapper">
        <div
          width={200}
          height={320}
          className={`poster ${visible ? "selectedPoster" : ""}`}
          style={{
            borderRadius: ".5rem",
            backgroundSize: "cover",
            backgroundImage: `url("https://image.tmdb.org/t/p/w300/${poster_path}")`,
          }}
        />
        <div
          onClick={() => {
            if (!clicked) {
              setClicked(true);
              thumpAndRedirect(title, uuid, genre_ids, release_date);
            }
          }}
          className="posterArea"
          ref={targetRef}
        >
          {" "}
        </div>
      </div>
      <h1 className="posterTitle">{title}</h1>
    </div>
  );
};

function App() {
  const [uuid, setUuid] = React.useState(null);
  const [arrowCode, setArrowCode] = React.useState(null);
  const initialData = [[], [], [], [], [], [], []];
  const [trending, setTrending] = React.useState(initialData);
  const [topRated, setTopRated] = React.useState(initialData);
  const [action, setAction] = React.useState(initialData);
  const [adventure, setAdventure] = React.useState(initialData);
  const [crime, setCrime] = React.useState(initialData);
  const [horror, setHorror] = React.useState(initialData);
  const [fantasy, setFantasy] = React.useState(initialData);
  const [history, setHistory] = React.useState(initialData);
  const [mystery, setMystery] = React.useState(initialData);
  const [scienceFiction, setScienceFiction] = React.useState(initialData);
  const [family, setFamily] = React.useState(initialData);
  const [romance, setRomance] = React.useState(initialData);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const requestPosters = async () => {
      await axios
        .get(`${urlBase}/trending/movie/day?api_key=${apiKey}`)
        .then(({ data }) =>
          setTrending({ title: "Trending", movies: data.results })
        );

      await axios
        .get(`${urlBase}/movie/top_rated?api_key=${apiKey}`)
        .then(({ data }) =>
          setTopRated({ title: "Top Rated", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=28`)
        .then(({ data }) =>
          setAction({ title: "Action", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=12`)
        .then(({ data }) =>
          setAdventure({ title: "Adventure", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=80`)
        .then(({ data }) => setCrime({ title: "Crime", movies: data.results }));

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=27`)
        .then(({ data }) =>
          setHorror({ title: "Horror", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=14`)
        .then(({ data }) =>
          setFantasy({ title: "Fantasy", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=36`)
        .then(({ data }) =>
          setHistory({ title: "History", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=9648`)
        .then(({ data }) =>
          setMystery({ title: "Mystery", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=878`)
        .then(({ data }) =>
          setScienceFiction({ title: "Science Fiction", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=10751`)
        .then(({ data }) =>
          setFamily({ title: "Family", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=10749`)
        .then(({ data }) =>
          setRomance({ title: "Romance", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=27`)
        .then(({ data }) =>
          setHorror({ title: "Horror", movies: data.results })
        );

      await axios
        .get(`${urlBase}/discover/movie?api_key=${apiKey}&with_genres=27`)
        .then(({ data }) =>
          setHorror({ title: "Horror", movies: data.results })
        );

      setIsLoading(false);
    };

    requestPosters();
  }, []);

  useDisablePinchZoomEffect();

  useEffect(() => {
    axios
      .get("/api/xeno/init")
      .then(function (response) {
        const { uuid, arrowCode } = response.data;
        setArrowCode(arrowCode);
        setUuid(uuid);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const notifyOrThump = React.useCallback(
    debounce(() => {
      if (peekedMovies.length <= 0) {
        return;
      }

      if (peekedMovies.length === 1) {
        const { title, genre_ids, releaseDate } = peekedMovies[0];
        const genres = getGenres(genre_ids);
        thump(title, uuid, genres, releaseDate);
        console.log(`[${title[0]}] javascript memmory resource allocation`);
      } else {
        const message = `${peekedMovies.length} movies on screen`;
        console.log(
          `[${peekedMovies.length}] javascript memmory resource allocation`
        );
        notify(message, uuid);
      }
    }, 2800),
    [thump, notify, uuid]
  );

  const handleSelect = ({ movie, posterPath, genre_ids, releaseDate }) => {
    if (peekedMovies.find((x) => x.title === movie)) {
      return;
    }

    peekedMovies.push({ title: movie, genre_ids, releaseDate });

    posterPathForMovie[movie] = posterPath;
    notifyOrThump();
  };

  const handleDeselect = ({ movie }) => {
    if (peekedMovies.length === 0) {
      return;
    }
    peekedMovies = peekedMovies.filter((x) => x.title !== movie);
    notifyOrThump();
  };

  const handleSearch = async (event, message) => {
    event.preventDefault();
    await notify(message, uuid);
    window.location.replace(`https://m.imdb.com/find?q=${message}`);
  };

  return (
    <div className="app-wrapper">
      <div className="imdbHeader">
        <a href="https://m.imdb.com">
          <MenuIcon />
        </a>
        <div className="logo">
          <a href="https://m.imdb.com">
            <ImdbLogo />
          </a>
        </div>
        <a
          className="headerLink hideMobile"
          href="https://m.imdb.com/what-to-watch"
        >
          Trending
        </a>
        <a
          className="headerLink hideMobile"
          href="https://pro.imdb.com/login/ap?u=/login/lwa&imdbPageAction=signUp&rf=cons_nb_hm&ref_=cons_nb_hm"
        >
          IMDbPro
        </a>
        <a
          className="headerLink hideSmallMobile"
          href="https://m.imdb.com/list/watchlist"
        >
          Watchlist
        </a>
        <a className="headerLink" href="https://m.imdb.com/registration/signin">
          Sign in
        </a>
      </div>
      <SearchImdb onSearch={handleSearch} />
      <h1 className="mainTitle">IMDb Top Picks</h1>
      <h2 className="subTitle">
        {arrowCode
          ? `${arrowCode.substring(
              0,
              2
            )} new titles added in the last ${arrowCode.substring(
              2,
              4
            )} minutes`
          : "Loading titles"}
      </h2>
      {!isLoading &&
        [
          trending,
          topRated,
          action,
          adventure,
          horror,
          crime,
          fantasy,
          history,
        ].map(({ movies, title }, index) => (
          <PosterRow
            key={index}
            uuid={uuid}
            movies={movies}
            title={title}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
          />
        ))}

      <h1 className="mainTitle">Featured today</h1>

      {!isLoading &&
        [
          trending,
          topRated,
          action,
          mystery,
          adventure,
          horror,
          scienceFiction,
          crime,
          fantasy,
          history,
          family,
          romance,
        ].map(({ movies, title }, index) => (
          <PosterRow
            key={index}
            movies={movies}
            uuid={uuid}
            title={title}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
          />
        ))}

      <div className="center">
        <img
          width="200px"
          heigth="320px"
          className="amazonLogo"
          src="/amazon.png"
          alt=""
        />
      </div>
      <div className="center copywrite">© 1990-2020 by IMDb.com, Inc.</div>
    </div>
  );
}

export default App;
