import React from "react";
import "./SearchImdb.css";

const SearchImdb = ({ onSearch }) => {
  const [query, setQuery] = React.useState();

  return (
    <div
      id="suggestion-search-container"
      className="nav-search__search-container _-7m-2d080hJg3V1G1WqY- sc-iwsKbI hAUoSP"
    >
      <form
        id="nav-search-form"
        name="nav-search-form"
        method="get"
        onSubmit={(e) => onSearch(e, query)}
        className="_3tGWq6W2TYPbP48TZtGEaB imdb-header__search-form sc-dnqmqq ibWJpR"
        role="search"
      >
        <div className="search-category-selector sc-htoDjs jAJuqP">
          <div className="sc-gzVnrw sVyDb navbar__flyout--breakpoint-m navbar__flyout--positionLeft">
            <div
              className="ipc-menu mdc-menu ipc-menu--not-initialized ipc-menu--on-baseAlt ipc-menu--anchored ipc-menu--with-checkbox ipc-menu--expand-from-top-left navbar__flyout--menu"
              data-menu-id="navbar-search-category-select"
              role="presentation"
            >
              <div
                className="ipc-menu__items mdc-menu__items"
                role="presentation"
              >
                <span id="navbar-search-category-select-contents">
                  <ul
                    className="ipc-list _2mcNnzNh_17zh5IV1Mukpr ipc-list--baseAlt"
                    role="menu"
                    aria-orientation="vertical"
                  >
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r _1VKSPp5LHTu0I_RQCOsBDd"
                      aria-label="All"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--search _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                        </svg>
                        All
                      </span>
                    </a>
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      aria-label="Titles"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--movie _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M18 4v1h-2V4c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1v1H6V4c0-.55-.45-1-1-1s-1 .45-1 1v16c0 .55.45 1 1 1s1-.45 1-1v-1h2v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h2v1c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1s-1 .45-1 1zM8 17H6v-2h2v2zm0-4H6v-2h2v2zm0-4H6V7h2v2zm10 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V7h2v2z" />
                        </svg>
                        Titles
                      </span>
                    </a>
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      aria-label="TV Episodes"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--television _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h5c1.1 0 1.99-.9 1.99-2L23 5a2 2 0 0 0-2-2zm-1 14H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1z" />
                        </svg>
                        TV Episodes
                      </span>
                    </a>
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      aria-label="Celebs"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--people _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
                        </svg>
                        Celebs
                      </span>
                    </a>
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      aria-label="Companies"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--business _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M12 7V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-8zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm9 12h-7v-2h2v-2h-2v-2h2v-2h-2V9h7c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1zm-1-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
                        </svg>
                        Companies
                      </span>
                    </a>
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      aria-label="Keywords"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--label _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84l3.96-5.58a.99.99 0 0 0 0-1.16l-3.96-5.58z" />
                        </svg>
                        Keywords
                      </span>
                    </a>
                    <li role="separator" className="ipc-list-divider" />
                    <a
                      role="menuitem"
                      className="ipc-list__item _1xTnkpad08eEWkqotpqi4r"
                      href="https://www.imdb.com/search/"
                      tabIndex={0}
                      aria-disabled="false"
                    >
                      <span className="ipc-list-item__text" role="presentation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--find-in-page _37fcCRzERfyeXeLFPsjHdY"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M20 19.59V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c.45 0 .85-.15 1.19-.4l-4.43-4.43c-.86.56-1.89.88-3 .82-2.37-.11-4.4-1.96-4.72-4.31a5.013 5.013 0 0 1 5.83-5.61c1.95.33 3.57 1.85 4 3.78.33 1.46.01 2.82-.7 3.9L20 19.59zM9 13c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z" />
                        </svg>
                        Advanced Search
                      </span>
                      <span
                        className="ipc-list-item__icon ipc-list-item__icon--post"
                        role="presentation"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          className="ipc-icon ipc-icon--chevron-right"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          role="presentation"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path d="M9.29 6.71a.996.996 0 0 0 0 1.41L13.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
                        </svg>
                      </span>
                    </a>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-search__search-input-container sc-bZQynM jIbbnv">
          <div
            aria-haspopup="listbox"
            aria-owns="react-autowhatever-1"
            aria-expanded="false"
            className="react-autosuggest__container"
          >
            <input
              type="text"
              autoComplete="off"
              onChange={(e) => setQuery(e.target.value)}
              aria-autocomplete="list"
              aria-controls="react-autowhatever-1"
              className="imdb-header-search__input GVtrp0cCs2HZCo7E2L5UU react-autosuggest__input"
              id="suggestion-search"
              name="q"
              placeholder="Search IMDb"
              autoCapitalize="off"
              autoCorrect="off"
            />
          </div>
        </div>
        <button
          id="suggestion-search-button"
          type="submit"
          className="nav-search__search-submit _2wo2z_hfPCHWGjZvUGi_f1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            className="ipc-icon ipc-icon--magnify"
            viewBox="0 0 24 24"
            fill="currentColor"
            role="presentation"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          </svg>
        </button>
      </form>
      <label
        id="imdbHeader-searchClose"
        className="ipc-icon-button imdb-header-search__state-closer ipc-icon-button--baseAlt ipc-icon-button--onBase"
        title="Close Search"
        role="button"
        tabIndex={0}
        aria-label="Close Search"
        aria-disabled="false"
        htmlFor="navSearch-searchState"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          className="ipc-icon ipc-icon--clear"
          viewBox="0 0 24 24"
          fill="currentColor"
          role="presentation"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
        </svg>
      </label>
    </div>
  );
};

export default SearchImdb;
